import React, { StrictMode, useState } from "react";
import { createRoot } from "react-dom/client";
import { ChainId, ThirdwebProvider, useAddress } from "@thirdweb-dev/react";
import Mint from "./components/Mint.tsx";
import PresaleMint from "./components/Presalemint.tsx";
import { AutoConnect } from "./components/AutoConnect.tsx";

const rootElement = document.getElementById("app");
const root = createRoot(rootElement);

function App() {
    const activeChainId = ChainId.Goerli;
    const address = useAddress();
    const [txHash, setTxHash] = useState("");

    return (
        <ThirdwebProvider desiredChainId={activeChainId}>
            <AutoConnect />
            <Mint setTxHash={setTxHash} />
            <PresaleMint setTxHash={setTxHash} />
            <p>txhash: {txHash}</p>
        </ThirdwebProvider>
    );
}

root.render(
    <StrictMode>
        <App />
    </StrictMode>
);
