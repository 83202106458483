import {
    useActiveClaimConditionForWallet,
    useAddress,
    useChainId,
    useClaimConditions,
    useClaimedNFTSupply,
    useClaimerProofs,
    useClaimIneligibilityReasons,
    useContract,
    useContractMetadata,
    useUnclaimedNFTSupply,
    Web3Button,
} from "@thirdweb-dev/react";
import React, { useState, useMemo } from "react";
import { BigNumber, ethers, utils } from "ethers";
import nftABI from "../abi/nftABI.json";

// useEffect(() => {
//     if (address) {
//         if (isWrongNetwork) {
//             console.error(`Wrong network, please switch your network.`);
//             if (switchNetwork) {
//                 switchNetwork(ChainId.BinanceSmartChainTestnet);
//             }
//         } else {
//             // if (constractAddress.isSuccess) {
//             //   getPaymentToken(constractAddress?.contract);
//             // } else {
//             //     console.error(
//             //     `Failed to make payment. Please try again later or contact the customer support.`
//             //   );
//             //   return;
//             // }
//         }
//     }
// }, [address, isWrongNetwork, switchNetwork]);

export default function Mint({ setTxHash }) {
    const myNftDropContractAddress =
        "0x821852ebe56aef81F61E8f24CEA62560e09B94Bf";
    const nftDrop = useContract(myNftDropContractAddress, nftABI);

    const chainId = useChainId();

    console.log(nftDrop, chainId);

    const minting = async () => {
        try {
            if (nftDrop?.contract && nftDrop.isSuccess) {
                const approval = await nftDrop?.contract.call("mint", "1");
                if (approval.hasOwnProperty("receipt")) {
                    setTxHash(approval?.receipt?.transactionHash);
                }
            }
            console.log(`done.`, nftDrop.isSuccess);
        } catch (e) {
            alert(`error` + e.message);
            return;
        }
    };
    return (
        <div>
            <button onClick={minting}>Mint</button>
        </div>
    );
}
