import {
    useActiveClaimConditionForWallet,
    useAddress,
    useChainId,
    useClaimConditions,
    useClaimedNFTSupply,
    useClaimerProofs,
    useClaimIneligibilityReasons,
    useContract,
    useContractMetadata,
    useUnclaimedNFTSupply,
    Web3Button,
} from "@thirdweb-dev/react";
import React, { useState, useMemo } from "react";
import { BigNumber, ethers, utils } from "ethers";
import nftABI from "../abi/nftABI.json";
import Whitelist from "./Whitelist.tsx"
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// useEffect(() => {
//     if (address) {
//         if (isWrongNetwork) {
//             console.error(`Wrong network, please switch your network.`);
//             if (switchNetwork) {
//                 switchNetwork(ChainId.BinanceSmartChainTestnet);
//             }
//         } else {
//             // if (constractAddress.isSuccess) {
//             //   getPaymentToken(constractAddress?.contract);
//             // } else {
//             //     console.error(
//             //     `Failed to make payment. Please try again later or contact the customer support.`
//             //   );
//             //   return;
//             // }
//         }
//     }
// }, [address, isWrongNetwork, switchNetwork]);

export default function PresaleMint({ setTxHash }) {
    const myNftDropContractAddress =
        "0x821852ebe56aef81F61E8f24CEA62560e09B94Bf";
    const nftDrop = useContract(myNftDropContractAddress, nftABI);
    const address = useAddress(); // Get connected wallet address

    const chainId = useChainId();

    const whitelistAddresses = new Whitelist();
    const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
    const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
    const claimingAddress = keccak256(address);

    const presaleMinting = async () => {
        const hexProof = await merkleTree.getHexProof(claimingAddress);
        const rootHash = merkleTree.getHexRoot();
        // console.log('Whitelist Merkle Tree\n', merkleTree.toString());
        console.log("Root Hash: ", rootHash);
        // console.log(merkleTree);
        // console.log(claimingAddress);
        console.log("Proof Hash: ", hexProof);

        try {
            if (nftDrop?.contract && nftDrop.isSuccess) {
                const approval = await nftDrop?.contract.call(
                    "presalemint",
                    "1",
                    hexProof,
                    {
                        value: ethers.utils.parseEther("0.1337"),
                    }
                );
                if (approval.hasOwnProperty("receipt")) {
                    setTxHash(approval?.receipt?.transactionHash);
                }
            }
            console.log(`done.`, nftDrop.isSuccess);
        } catch (e) {
            alert(`error` + e.message);
            return;
        }
    };

    return (
        <div>
            <button onClick={presaleMinting}>Presalemint</button>
        </div>
    );
}
